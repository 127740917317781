<template>
	<div class="goodsList">
		<!-- <div class="ad">
			<img class="regular" src="../../assets/sources/banner.png">
		</div> -->
		<div class="cate">{{total}} results for '{{$route.query.key}}'</div>
		<div class="filter">
			<div class="main">
				<div class="options">
				</div>
				<div class="total">{{total}}件商品</div>
			</div>
		</div>
		<div class="list">
			<div class="item" v-for="val in list" :key="val">
				<GoodsItem :detail="val"></GoodsItem>
			</div>
		</div>
		<!-- <div class="more" @click="loadMore" v-if="list.length > 0">{{$t('nav.more')}}</div> -->
	</div>
</template>

<script>
	import GoodsItem from '../../components/Goods/listItem.vue'
	import Api from '../../api/request.js'
	export default {
		name: 'Adlink',
		components: {
			GoodsItem
		},
		data() {
			return {
				page: {
					page: 1,
					size: 12
				},
				list: [],
				total: 0,
				currentCate: '',
				sons: []
			};
		},
		created() {
			this.getList();
		},
		methods: {
			getList() {
				//this.$loading.start();
				Api.Goods.recommand({
					spu_ids: this.$route.query.spu_ids,
					page: this.page.page,
					pageLimit: this.page.size,
				}).then(res => {
					if (this.page.page == 1) {
						this.list = res.data.data;
						this.total = res.data.data.length;
					} else {
						if (res.data.data.list.length == 0) {
							this.$dialog.message(this.$t('tip.noMore'));
						} else {
							this.list = this.list.concat(res.data.data);
						}
					}
					this.$loading.done();
				})
			},
			loadMore() {
				this.page.page += 1;
				this.getList();
			}
		}
	};
</script>

<style lang="less" scoped>
	.ad {
		height: 540px;
	}

	.cate {
		font-size: 28px;
		font-weight: bold;
		line-height: 40px;
		margin: 30px 0;
		text-align: center;
	}

	.filter {
		padding: 0 7px;

		.main {
			height: 56px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid #757575;
			border-bottom: 1px solid #757575;

			.options {
				display: flex;
				align-items: center;

				.item {
					font-size: 14px;
					padding: 0 30px;
					cursor: pointer;
				}

				.devide {
					&:first-child {
						display: none;
					}

					&+.item {
						padding: 0 40px;
					}

					width: 1px;
					height: 30px;
					background: #BABABA;
				}
			}
		}

		.total {
			font-size: 14px;
			margin-right: 40px;
		}
	}

	.list {
		display: flex;
		flex-wrap: wrap;
		padding: 0 0 60px 7px;

		.item {
			width: calc((100vw - 32px) / 4);
			margin: 50px 6px 0 0;
		}
	}

	.more {
		height: 50px;
		line-height: 50px;
		text-align: center;
		border: 2px solid #BBB;
		margin: 0 21px 84px;
		font-size: 16px;
		cursor: pointer;
	}

	@media screen and (max-width: 500px) {
		.ad {
			height: 28.1vw;
		}

		.cate {
			font-size: 4vw;
			font-weight: bold;
			margin: 4vw 0;
			text-align: center;
		}

		.filter {
			padding: 0 7px;

			.main {
				height: 12vw;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-top: 1px solid #757575;
				border-bottom: 1px solid #757575;

				.options {
					width: 100%;
					display: flex;
					align-items: center;

					.item {
						font-size: 3vw;
						padding: 0 !important;
						flex: 1;
						text-align: center;
					}

					.devide {
						display: none;
					}
				}
			}

			.total {
				display: none;
				font-size: 14px;
				margin-right: 40px;
			}
		}

		.list {
			display: flex;
			flex-wrap: wrap;
			padding: 0 0 6vw 6px;

			.item {
				width: calc((100vw - 18px) / 2);
				margin: 6px 6px 0 0;
			}
		}

		.more {
			height: 12vw;
			line-height: 13vw;
			border: 1px solid #BBB;
			font-size: 4vw;
		}
	}
</style>
