var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"listItem",on:{"click":function($event){return _vm.$router.push(
        ("/" + (_vm.$store.state.platform == 'pc' ? 'GoodsDetail' : 'Detail_Mobile') + "?id=" + (_vm.detail.spuId))
      )}}},[_c('div',{staticClass:"pic"},[_c('img',{staticClass:"regular",attrs:{"src":_vm.detail.cover}}),(!_vm.isInLike)?[_c('img',{staticClass:"like",attrs:{"src":_vm.detail.isCollect && _vm.detail.isCollect == 1
              ? require('../../assets/icon/liked.png')
              : require('../../assets/icon/like.png')},on:{"click":function($event){$event.stopPropagation();return _vm.$bus.$emit('addLike', _vm.detail.spuId)}}})]:[_c('img',{staticClass:"like",attrs:{"src":require("../../assets/icon/close.png")},on:{"click":function($event){$event.stopPropagation();return _vm.$bus.$emit('removeCollect', _vm.detail.collectId)}}})]],2),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.detail.spuName))]),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.detail.price)+"€")]),(_vm.isstock)?_c('div',{staticClass:"btn deep",on:{"click":function($event){$event.stopPropagation();return _vm.handleAddCart($event)}}},[_vm._v(" "+_vm._s(_vm.$t("goodDetail.addCart"))+" ")]):_c('div',{staticClass:"btn nodeep"},[_vm._v(" "+_vm._s(_vm.$t("goodDetail.agotado"))+" ")])]),_c('div',{on:{"click":function($event){$event.stopPropagation();return (function () {})($event)}}},[_c('el-dialog',{attrs:{"title":"Talla","visible":_vm.listVisible,"width":"30%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.listVisible=$event}}},[_c('div',{staticClass:"list-content"},_vm._l((_vm.detail.skus),function(item,index){return _c('p',{key:index,staticClass:"list-sku",on:{"click":function($event){$event.stopPropagation();return (function () {
                _vm.$bus.$emit('addCart', item.skuId || item.sku_id);
                _vm.listVisible = false;
              })($event)}}},[_vm._v(" "+_vm._s(item.specInfo ? item.specInfo.replace(/Talla:/g, "") : item.spec_info ? item.spec_info.replace(/Talla:/g, "") : "")+" ")])}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }